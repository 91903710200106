<template>
  <img :src="`data:${mime};base64,${safeData}`" v-if="mime.startsWith('image')" alt="media" />
  <video
    :src="`data:${mime};base64,${safeData}`"
    controls
    loop
    muted
    v-else-if="mime.startsWith('video')"
  ></video>
  <div v-else>
    <div>Unsupported Media {{ mime }}</div>
  </div>
</template>

<script>
export default {
  name: "Media.Component",
  props: {
    data: {
      type: String,
      required: true,
    },
    mime: {
      type: String,
      default: () => "image/jpeg",
    },
  },
  computed: {
    safeData() {
      if (this.data && this.data.indexOf("base64,") !== -1) {
        return this.data.split("base64,")[1];
      } else {
        return this.data;
      }
    },
  },
};
</script>

<style scoped>
img,
video {
  height: 200px;
  object-fit: contain;
  object-position: center right;
}
div {
  min-width: 100px;
  min-height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #666;
  padding: 5px;
}
</style>
